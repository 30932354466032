export const PrivateLandingNav = [
  {
    name: "Admin",
    path: "/admin",
  },
  {
    name: "Clients",
    path: "/clients",
  },
  {
    name: "Projects",
    path: "/projects",
  },
  {
    name: "Research",
    path: "/research",
  },
  {
    name: "Templates",
    path: "/templates",
  },
];
export const publicNavOp = [
  "/login",
  "/register",
  "/forgot-password",
  "/public/review-feedback",
  "/public/approval",
];
export const privateSidebarNavOp = [
  "/projects",
  "/projects/research",
  "/projects/add-tool",
  "/projects/settings",
  "/projects/settings/core-customers",
  "/projects/settings/research",
  "/projects/settings/competitors",
  "/projects/settings/user-access",
  "/projects/add-tool",
  "/projects/artwork-people",
  "/projects/review-feedback",
  "/projects/approval",
];
export const PublicLandingNav = [
  {
    name: "Register",
    path: "/register",
  },
  {
    name: "Login",
    path: "/login",
  },
];

export const profileRoutes = [
  {
    name: "My Details",
    path: "/profile",
  },
  {
    name: "Company Details",
    path: "/profile/company-details",
  },
  // TODO
  // {
  //   name: "Billing & Invoices",
  //   path: "/profile/billing-invoice",
  // },
  {
    name: "Integrations",
    path: "/profile/integrations",
  },
  {
    name: "User Management",
    path: "/profile/user-management",
  },
  {
    name: "White Label",
    path: "/profile/white-label",
  },
  // {
  //   name: "Client Archive",
  //   path: "/profile/client-archive",
  // },
  {
    name: "Logout",
    btn: true,
  },
];

export const settingRoutes = [
  {
    name: "Overview",
    path: "/projects/settings",
  },
  {
    name: "Core Customers",
    path: "/projects/settings/core-customers",
  },
  {
    name: "Research",
    path: "/projects/settings/research",
  },
  {
    name: "Competitors",
    path: "/projects/settings/competitors",
  },
  {
    name: "User Access",
    path: "/projects/settings/user-access",
  },
];

export const BrandStrategyRoutes = [
  {
    name: "Introduction",
    path: "/projects/add-tool/brand-strategy",
  },
  {
    name: "What is discovery?",
    path: "/projects/add-tool/brand-strategy/discovery",
  },
  {
    name: "Research",
    path: "/projects/add-tool/brand-strategy/research",
  },
  {
    name: "Settings & Slides",
    path: "/projects/add-tool/brand-strategy/settings-slides",
  },
  {
    name: "Workshop",
    path: "/projects/add-tool/brand-strategy/workshop",
  },
  {
    name: "Download",
    path: "/projects/add-tool/brand-strategy/download",
  },
];

export const settingCleintsRoutes = [
  {
    name: "Overview",
    path: "",
  },
  {
    name: "Details",
    path: "details",
  },
  {
    name: "Branding",
    path: "branding",
  },
  // {
  //   name: "Core Customers",
  //   path: "core-customers",
  // },
  // {
  //   name: "Research",
  //   path: "research",
  // },
  // {
  //   name: "Competitors",
  //   path: "competitors",
  // },
];

export const adminRoutes = [
  {
    name: "Reporting",
    path: "/admin",
  },
  {
    name: "Research Documents",
    path: "/admin/research-documents",
  },
  {
    name: "Transactions",
    path: "/admin/transactions",
  },
  {
    name: "Agencies",
    path: "/admin/agencies",
  },
  {
    name: "Users",
    path: "/admin/users",
  },
];
